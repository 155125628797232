import  React,{useEffect,useState} from "react"
import Header from '@Components/Header/Header';
import Footer from '@Components/Footer/Footer';
import BookViewing from "@Components/BookViewing/BookViewing"
import  './BookViewing.scss';
import { Helmet } from "react-helmet";
import SEO from "@Components/seo";
const BookViewingPage = ( props ) => {
  const [state,setState] = React.useState({
    showMenu:false
  })
  const [address,setAddress]=React.useState("")

  const handlerClick = () => {
    // console.log('okkk')
    setState({...state,showMenu:!state.showMenu})
  }
  useEffect(() => {
     if(sessionStorage.getItem("addressdisplay")){
      setAddress(sessionStorage.getItem("addressdisplay"))
     }
  }, [])

  
  return(
    <div className={state.showMenu ? "wrapper bookview-page header-style open-search-block" : "wrapper bookview-page header-style"}>
              <SEO title={'Book a Property Viewing Manchester'} description={`Book a viewing for a ${address} property that grabbed your attention with Bentley Hurst. Fill out our simple form with details about you & the property to start.`} />
      <Helmet
        bodyAttributes={{
            class: `templates-default-template alias`
        }}
      />
    <Header showMenu={state.showMenu} handlerClick={handlerClick}/>
    <BookViewing/> 
    <Footer/>
    </div>
         
  
)
  }



export default BookViewingPage