import React, { useState, useEffect } from "react";
import { isEmpty } from 'lodash';
import { Form, Container, Row, Col } from 'react-bootstrap';
import StepOne from '../BookViewing/StepOne/StepOne'
import StepTwo from '../BookViewing/StepTwo/StepTwo'
import CalendarComponents from '../BookViewing/CalendarBlock/CalendarBlock'
import ScrollAnimation from 'react-animate-on-scroll';
import './BookViewing.scss'
import { postFormData } from "./../forms/api/Api"
import { getGAUserID } from "./../forms/util"
import axios from "axios"
import ReCaptchaBox from '@Components/forms/elements/recaptcha'
import * as qs from "query-string"
import moment from "moment"
import { getUser } from "@starberry/myaccount-website-utils";
import noImage from "../../images/no-image.png";
import { get } from 'lodash';
const BookViewing = (props) => {


  const userData = getUser();
  const [data, updateData] = useState({})
  const property_image = get(data, "images[0]['730x492']", get(data, 'images[0].url', noImage))
  const recaptchaRef = React.createRef();
  const [formvalues, setFormvalues] = useState("");
  const [token, setToken] = useState("");
  const [handleonVerifytoken, setHandleonVerifytoken] = useState(0);
  const handleBack = () => window.history.back();
  useEffect(() => {
    window.scrollTo(0, 0);
    updateData(JSON.parse(sessionStorage.getItem('data')))
  }, []);



  useEffect(() => {
    window.scrollTo(0, 0);

    // Prefill user data if user logined in
    if (!isEmpty(userData)) {
      if (!sessionStorage.getItem('name'))
        setName(sessionStorage.setItem('name', userData?.name || ''));
      if (!sessionStorage.getItem('lastName'))
        setLastName(sessionStorage.setItem('lastName', userData?.surname || ''));
      if (!sessionStorage.getItem('email'))
        setEmail(sessionStorage.setItem('email', userData?.email || ''));
      if (!sessionStorage.getItem('telephone'))
        setPhone(sessionStorage.setItem('telephone', userData?.mobile || ''));
    }

    setName(sessionStorage.getItem('name') != null ? sessionStorage.getItem('name') : userData?.name || '')
    setLastName(sessionStorage.getItem('lastName') != null ? sessionStorage.getItem('lastName') : userData?.surname || '')
    setEmail(sessionStorage.getItem('email') != null ? sessionStorage.getItem('email') : userData?.email || '')
    setPhone(sessionStorage.getItem('telephone') != null ? sessionStorage.getItem('telephone') : userData?.mobile || '')
    setPropertySell(sessionStorage.getItem('propertysell') != null ? sessionStorage.getItem('propertysell') : false)
    setMarket(sessionStorage.getItem('market') != null ? sessionStorage.getItem('market') : false)
    setPurchase(sessionStorage.getItem('purchase') != null ? sessionStorage.getItem('purchase') : false)
    setMortgage(sessionStorage.getItem('mortgage') != null ? sessionStorage.getItem('mortgage') : false)
  }, []);
  const [step, setStep] = useState(0)
  const [name, setName] = useState('')
  const [nameError, setNameError] = useState(false)
  const [lastName, setLastName] = useState('')
  const [lastNameError, setLastNameError] = useState(false)
  const [email, setEmail] = useState('')
  const [emailError, setEmailError] = useState(false)
  const [valemailError, setValEmailError] = useState(false)
  const [phone, setPhone] = useState('')
  const [phoneError, setPhoneError] = useState(false)
  const [valphoneError, setValPhoneError] = useState(false)
  const [propertySell, setPropertySell] = useState(false)
  const [market, setMarket] = useState(false)
  const [purchase, setPurchase] = useState(false)
  const [mortgage, setMortgage] = useState(false)
  const [submitted, stepSubmitted] = useState(false)

  const onChange = (e) => {

    sessionStorage.setItem(e.target.name, e.target.value)
    if (e.target.name === 'name') {
      setName(e.target.value)
      if (e.target.value !== "") {
        setNameError(false)
      }
      else {
        setNameError(true)
      }
    }
    if (e.target.name === 'lastName') {
      setLastName(e.target.value)
      if (e.target.value !== "") {
        setLastNameError(false)
      }
      else {
        setLastNameError(true)
      }
    }
    if (e.target.name === 'email') {
      setEmail(e.target.value)
      if (e.target.value === "") {
        setEmailError(true)
      }
      else if (e.target.value.match("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,}$") === null) {
        setValEmailError(true)
        setEmailError(false)
      }
      else {
        setEmailError(false)
        setValEmailError(false)
      }
    }
    if (e.target.name === 'telephone') {
      setPhone(e.target.value)
      if (e.target.value === "") {
        setPhoneError(true)
      }
      else if (e.target.value.match("^[+]*[0-9-()]+(\\s+[0-9-()]+)*.{9}$") === null) {
        setValPhoneError(true)
        setPhoneError(false)
      }
      else {
        setPhoneError(false)
        setValPhoneError(false)
      }
    }
    if (e.target.name === 'propertysell') {
      setPropertySell(e.target.value)
    }
    if (e.target.name === 'market') {
      setMarket(e.target.value)
    }
    if (e.target.name === 'purchase') {
      setPurchase(e.target.value)
    }
    if (e.target.name === 'mortgage') {
      setMortgage(e.target.value)
    }

  }
  const next = event => {

    if (step === 0) {
      if (!name || !lastName || !email || !phone || email.match("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,}$") === null || phone.match("^[+]*[0-9-()]+(\\s+[0-9-()]+)*.{9}$") === null) {
        if (!name) {
          setNameError(true)
        }
        if (!lastName) {
          setLastNameError(true)
        }
        if (!email) {
          setEmailError(true)
        }
        if (email.match("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,}$") === null) {
          setValEmailError(true)
        }
        if (!phone) {
          setPhoneError(true)
        }
        if (phone.match("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,}$") === null) {
          setValPhoneError(true)
        }
      }
      else {
        window.scrollTo({
          top: 0,
          behavior: 'smooth'
        })
        setStep(step + 1)
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          'event': 'formSubmit',
          'formType': 'form-book-a-viewing-step-one',
          'formId': 'form-book-a-viewing-step-one',
          'formName': 'Book a Viewing Step One',
          'formLabel': 'Book a Viewing Step One'
        });
        
      }
    }
    else if (step === 1) {
      if (!propertySell || !market || !mortgage || !purchase) {
        stepSubmitted(true)
      }
      else {
        window.scrollTo({
          top: 0,
          behavior: 'smooth'
        })
        
        if(data.department == "international"){
          formsubmitPost(event, 2); 
          
        }else{
          setStep(step + 1);
        }
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          'event': 'formSubmit',
          'formType': 'form-book-a-viewing-step-two',
          'formId': 'form-book-a-viewing-step-two',
          'formName': 'Book a Viewing Step Two',
          'formLabel': 'Book a Viewing Step Two'
        });
      }
    }
    else if (step === 2) {
      formsubmitPost(event, 1);

    }
  }

  const formsubmitPost = (event, moveStep) =>{
      //debugger
      const formsdata = (event.target);
      event.preventDefault();
      const json = {}
      Object.keys(formsdata).map(key => (
        json[formsdata[key].name] = (formsdata[key].checked) ? 'yes' : formsdata[key].value
      ))
      json['ga_client_id'] = getGAUserID();
      json['email_temp_user'] = "book_user";
      json['email_temp_admin'] = "book_admin";
      json['formname'] = "Book a Viewing";
      if (json['subject']) {
        json['formname'] = 'Book a Viewing';
      }
      json['email'] = sessionStorage.getItem('email');
      json['name'] = sessionStorage.getItem('name');
      json['lastName'] = sessionStorage.getItem('lastName');
      json['telephone'] = sessionStorage.getItem('telephone');
      json['propertysell'] = sessionStorage.getItem('propertysell');
      json['market'] = sessionStorage.getItem('market');
      json['purchase'] = sessionStorage.getItem('purchase');
      json['mortgage'] = sessionStorage.getItem('mortgage');
      const data = JSON.parse(sessionStorage.getItem('data'))
      const url = sessionStorage.getItem('property_url')
      json['display_address'] = data?.display_address;
      json['url'] = url;
      json['address'] = sessionStorage.getItem('address');
      json['date_time'] = moment(sessionStorage.getItem('date')).format("YYYY-MM-DD HH:mm");
      json['g-recaptcha-response'] = token;
      json['to_email_id'] = data?.department == "international" ? process.env.INTERNATIONAL_MAIL_TO: process.env.BOOKAVIEW_MAIL_TO
      setFormvalues(json);

      recaptchaRef.current.execute();
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      })
      setStep(step + moveStep)
      window.scrollTo(0, 0);
  }
  const previous = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    })
    setStep(step - 1)
  }
  useEffect(() => {
    if (token !== '') {
      const processFromData = async () => {
        let formData = new FormData();

        const propertyData = {
          search_type : data.search_type,
          display_address : data.display_address,
          property_id: data.crm_id,
          negotiator_id: data?.crm_negotiator_id?.ID
        }
        formvalues['g-recaptcha-response'] = token;
        formData.append('data', JSON.stringify({...formvalues, ...propertyData}));
        formData.append('propertyData', JSON.stringify(propertyData));

        postFormData(formData).then(apiRes => {

          const axiosOptions_email = {
            url: '/.netlify/functions/server/book_a_viewing',
            method: "post",
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
            data: qs.stringify(formvalues),
          }

          axios(axiosOptions_email)
            .then(response => {
              setHandleonVerifytoken(1);
              sessionStorage.removeItem('email');
              sessionStorage.removeItem('name');
              sessionStorage.removeItem('lastName');
              sessionStorage.removeItem('telephone');
              sessionStorage.removeItem('propertysell');
              sessionStorage.removeItem('market');
              sessionStorage.removeItem('purchase');
              sessionStorage.removeItem('mortgage');
              sessionStorage.removeItem('date');
              sessionStorage.removeItem('data');

            })
            .catch(err => {

            }
            );
        });

        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          'event': 'formSubmit',
          'formType': 'form-book-a-viewing',
          'formId': 'form-book-a-viewing',
          'formName': 'Book a Viewing',
          'formLabel': 'Book a Viewing'
        });

      }
      processFromData();
    }
  }, [token]);
  const handleonVerify = token => {
    setToken(token);
  };
  return (

    <div style={{ position: "relative" }}>
      <Container>

        <Row>
          <Col lg="6">
            <Form>
              {step === 0 ?
                <StepOne
                  next={next}
                  previous={previous}
                  onChange={onChange}
                  name={name}
                  nameError={nameError}
                  lastName={lastName}
                  lastNameError={lastNameError}
                  email={email}
                  valemailError={valemailError}
                  emailError={emailError}
                  phone={phone}
                  phoneError={phoneError}
                  valphoneError={valphoneError}
                  handleBack={handleBack}
                  data={data}
                />
                : step === 1 ?
                  <StepTwo
                    next={next}
                    previous={previous}
                    onChange={onChange}
                    name={name}
                    submitted={submitted}
                    propertySell={propertySell}
                    purchase={purchase}
                    mortgage={mortgage}
                    market={market}
                    handleBack={handleBack}

                  />
                  :
                  step === 2 ?
                    <CalendarComponents
                      next={next}
                      previous={previous}
                      onChange={onChange}
                      handleBack={handleBack}
                    />
                    :
                    step === 3 ?
                      <div className="steptwo">
                        <div className="d-lg-block">
                          <div onClick={handleBack} onKeyPress={handleBack} className="back_icon"><i className="icon-back"></i> Back to Property Details</div>
                        </div>
                        {handleonVerifytoken==1 ?
                        <ScrollAnimation animateIn='fadeIn' animateOnce={true}>
                          <h2><span>Thank you for contacting us. A member of our team will contact you shortly.</span></h2>
                          <div style={{ height: '100px' }} ></div>
                        </ScrollAnimation> : 
                        <ScrollAnimation animateIn='fadeIn' animateOnce={true}>
                          <h2><span>Please Wait...</span></h2>
                          <div style={{ height: '100px' }} ></div>
                        </ScrollAnimation>
                        }
                      </div>
                      : null}
            <ReCaptchaBox
              className="py-2"
              captRef={recaptchaRef}
              key="captcha"
              handleonVerify={handleonVerify}
            />
            </Form>
          </Col>
          {property_image &&
              <div className="bg-image-book-view" style={{ backgroundImage: `url(${property_image})` }}></div>
          }

        </Row>

      </Container>
    </div>


  )
}



export default BookViewing
